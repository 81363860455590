import { Grid, Layout, Menu, Row, Image, Drawer } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import {
  HomeOutlined,
  TagsOutlined,
  ProductOutlined,
  SnippetsOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../context";

const { useBreakpoint } = Grid;
const { Sider } = Layout;

interface ISideMenuProps {
  drawerCollapsed: boolean;
  setDrawerCollapsed: (drawerCollapsed: boolean) => void;
}

const SideMenu = (props: ISideMenuProps) => {
  const { user } = useContext(UserContext);

  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems: ItemType<MenuItemType>[] = [
    {
      label: "Dashboard",
      key: "/",
      icon: <HomeOutlined />,
    },
    {
      label: "Müşteriler",
      key: "/musteriler",
      icon: <UsergroupAddOutlined />,
    },
    user?.roles.includes("ROLE_ADMIN") && {
      label: "Temsilciler",
      key: "/temsilciler",
      icon: <UserOutlined />,
    },
    user?.roles.includes("ROLE_ADMIN") && {
      label: "Kaynaklar",
      key: "/kaynaklar",
      icon: <TagsOutlined />,
    },
    user?.roles.includes("ROLE_ADMIN") && {
      label: "Abonelikler",
      key: "/abonelikler",
      icon: <ProductOutlined />,
    },
    user?.roles.includes("ROLE_ADMIN") && {
      label: "Durumlar",
      key: "/durumlar",
      icon: <SnippetsOutlined />,
    },
  ];

  const content = (
    <>
      <Row justify="center" align="middle" style={{ height: 64 }}>
        <Image
          width={48}
          height={48}
          src="/assets/primary-circle-logo.png"
          alt="primary-circle-logo"
          preview={false}
        />
      </Row>
      <Menu
        selectedKeys={[location?.pathname]}
        mode="inline"
        items={menuItems}
        onClick={({ key }) => navigate(key)}
      />
    </>
  );

  return screens.md ? (
    <Sider
      defaultCollapsed
      theme="light"
      collapsible
      width={240}
      collapsedWidth={64}
      style={{
        zIndex: 100,
        boxShadow: "rgba(19, 25, 32, 0.2) 0px 8px 8px",
      }}
    >
      {content}
    </Sider>
  ) : (
    <Drawer
      closable={false}
      width={300}
      placement="left"
      open={props.drawerCollapsed}
      onClose={() => props.setDrawerCollapsed(!props.drawerCollapsed)}
    >
      {content}
    </Drawer>
  );
};

export default SideMenu;
