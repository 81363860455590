import { Select } from "antd";
import { useEffect, useState } from "react";
import { StateCrudApi } from "../../../client/EntityApi";

const ParentStateSelect = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    StateCrudApi.getAll(false)
      .then((response) => setOptions(response["hydra:member"]))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Select
      {...props}
      loading={loading}
      placeholder="Üst durum seçiniz"
      options={options}
    />
  );
};

export default ParentStateSelect;
