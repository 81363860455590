import { useEffect, useState } from "react";
import { ConstantContext, UserContext } from "../../context";
import { Navigate, Outlet } from "react-router-dom";
import { Button, Grid, Layout, Row } from "antd";
import { loginPath } from "../../router";
import ProfileMenu from "./ProfileMenu";
import SideMenu from "./SideMenu";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import GeneralApi from "../../client/GeneralApi";
import AuthApi from "../../client/AuthApi";
import { isLoggedIn } from "../../helpers/AuthHelper";
import { colors } from "../../theme";

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const SiderLayout = () => {
  const screens = useBreakpoint();
  const [constants, setConstants] = useState<any>();
  const [user, setUser] = useState<any>();
  const [drawerCollapsed, setDrawerCollapsed] = useState<any>();

  useEffect(() => {
    if (!isLoggedIn()) return;
    GeneralApi.getConstants().then((response) => setConstants(response));
    AuthApi.currentUser().then((response) => setUser(response));
  }, []);

  return !isLoggedIn() ? (
    <Navigate to={loginPath} />
  ) : (
    <ConstantContext.Provider value={constants}>
      <UserContext.Provider value={{ user, setUser }}>
        <Layout style={{ minHeight: "100vh" }}>
          <SideMenu
            drawerCollapsed={drawerCollapsed}
            setDrawerCollapsed={setDrawerCollapsed}
          />
          <Layout>
            <Header style={{ padding: "0px 32px", background: colors.white }}>
              <Row
                style={{ height: "100%" }}
                justify={screens.md ? "end" : "space-between"}
                align="middle"
              >
                {!screens.md && (
                  <Button
                    type="text"
                    size="large"
                    icon={<MenuUnfoldOutlined />}
                    onClick={() => setDrawerCollapsed(!drawerCollapsed)}
                  />
                )}
                <ProfileMenu />
              </Row>
            </Header>
            <Content style={{ padding: 16, background: colors.background }}>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </UserContext.Provider>
    </ConstantContext.Provider>
  );
};

export default SiderLayout;
