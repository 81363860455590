import { Form, Input } from "antd";
import { max, min, required } from "../../../helpers/FormRuleHelper";

interface ISubscriptionCPComponentProps {
  isEdit?: boolean;
}

export const getSubscriptionCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Abonelik Adı", dataIndex: "name", key: "name" },
  ];
};

const SubscriptionCPComponent = (props: ISubscriptionCPComponentProps) => {
  return (
    <>
      {props.isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Abonelik Adı"
        rules={[required, min(2), max(50)]}
      >
        <Input placeholder="Abonelik adı giriniz" />
      </Form.Item>
    </>
  );
};

export default SubscriptionCPComponent;
