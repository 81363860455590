import { StateCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/common/crud/CrudTable";
import StateCPComponent, {
  getStateCPColumns,
} from "../../components/forms/crud/StateCPComponent";

const StateCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Durum"
      api={StateCrudApi}
      columns={getStateCPColumns()}
      addFormItems={<StateCPComponent />}
      editFormItems={<StateCPComponent isEdit={true} />}
      setEditFields={(row) => ({ parent: row.parent?.["@id"] })}
      expandable={{ rowExpandable: () => false }}
    />
  );
};

export default StateCrudPage;
