import { useContext } from "react";
import { AgentCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/common/crud/CrudTable";
import AgentCPComponent, {
  getAgentCPColumns,
} from "../../components/forms/crud/AgentCPComponent";
import { ConstantContext } from "../../context";

const AgentCrudPage = () => {
  const constants = useContext(ConstantContext);

  const beforeOperation = (values: any) => {
    values.roles = [values.roles];
    return values;
  };

  return (
    <CrudTable
      entityLabel="Temsilci"
      api={AgentCrudApi}
      columns={getAgentCPColumns(constants)}
      addFormItems={<AgentCPComponent constants={constants} />}
      editFormItems={<AgentCPComponent constants={constants} isEdit={true} />}
      beforeAddOperation={(values) => beforeOperation(values)}
      beforeEditOperation={(values) => beforeOperation(values)}
      setEditFields={(row) => ({ roles: row.roles?.[0] ?? "ROLE_USER" })}
    />
  );
};

export default AgentCrudPage;
