import { createBrowserRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import NotFoundPage from "./pages/common/NotFoundPage";
import SiderLayout from "./components/layouts/SiderLayout";
import AuthLayout from "./components/layouts/AuthLayout";
import LoginPage from "./pages/common/LoginPage";
import AgentCrudPage from "./pages/crud/AgentCrudPage";
import SourceCrudPage from "./pages/crud/SourceCrudPage";
import SubscriptionCrudPage from "./pages/crud/SubscriptionCrudPage";
import StateCrudPage from "./pages/crud/StateCrudPage";
import LeadCrudPage from "./pages/crud/LeadCrudPage";
import LeadDetailPage from "./pages/LeadDetailPage";

export const loginPath = "/giris-yap";
export const successPath = "/";

const getPrivateRoutes = () => [
  { path: "/", element: <Dashboard /> },
  { path: "/musteriler", element: <LeadCrudPage /> },
  { path: "/temsilciler", element: <AgentCrudPage /> },
  { path: "/kaynaklar", element: <SourceCrudPage /> },
  { path: "/abonelikler", element: <SubscriptionCrudPage /> },
  { path: "/durumlar", element: <StateCrudPage /> },
  { path: "/musteri-detay/:id", element: <LeadDetailPage /> },
];

const getAuthRoutes = () => [{ path: "/giris-yap", element: <LoginPage /> }];

export const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: getAuthRoutes(),
  },
  {
    element: <SiderLayout />,
    children: getPrivateRoutes(),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);
