import { DatePicker, Form, Input, Select } from "antd";
import {
  getConstantValue,
  getFormattedDate,
} from "../../../helpers/UtilHelper";
import { email, max, min, required } from "../../../helpers/FormRuleHelper";
import InputPhone from "../../common/InputPhone";
import AgentSearchSelect from "../formItems/AgentSearchSelect";
import SourceSearchSelect from "../formItems/SourceSearchSelect";
import StateTreeSelect from "../formItems/StateTreeSelect";
import TableSearchFilter from "../../common/TableSearchFilter";
import StateSearchSelect from "../formItems/StateSearchSelect";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

interface ILeadCPComponentProps {
  isEdit?: boolean;
  isAdmin?: boolean;
  constants?: any;
}

export const getLeadCPColumns = (constants: any, isAdmin?: boolean) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Durum / Alt Durum",
      dataIndex: "state",
      key: "state.id",
      render: (value: any) =>
        value ? (value.parent?.name ?? "-") + " / " + value?.name : "-",
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <StateSearchSelect
            style={{ width: 250 }}
            optionValueKey="id"
            allowClear={false}
            value={props.selectedKeys[0]}
            onSelect={(value: any) => props.setSelectedKeys([value])}
          />
        </TableSearchFilter>
      ),
    },
    { title: "Ad Soyad", dataIndex: "fullName", key: "fullName" },
    {
      title: "E-Posta",
      dataIndex: "email",
      key: "email",
      render: (value: any) => value ?? "-",
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
      render: (value: any) => value ?? "-",
    },
    {
      title: "İkincil Telefon",
      dataIndex: "phone2",
      key: "phone2",
      render: (value: any) => value ?? "-",
    },
    {
      title: "İlk Kaynak",
      dataIndex: "source",
      key: "source.id",
      render: (value: any) => (value ? value.name : "-"),
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <SourceSearchSelect
            style={{ width: 200 }}
            optionValueKey="id"
            allowClear={false}
            value={props.selectedKeys[0]}
            onSelect={(value: any) => props.setSelectedKeys([value])}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Son Kaynak",
      dataIndex: "lastSource",
      key: "lastSource.id",
      render: (value: any) => (value ? value.name : "-"),
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <SourceSearchSelect
            style={{ width: 200 }}
            optionValueKey="id"
            allowClear={false}
            value={props.selectedKeys[0]}
            onSelect={(value: any) => props.setSelectedKeys([value])}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Müşteri Tipi",
      dataIndex: "type",
      key: "type",
      filters: constants?.leadTypes,
      render: (value: any) => getConstantValue(value, constants?.leadTypes),
    },
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <RangePicker
            showTime
            value={props.selectedKeys?.map((selectedKey: any) =>
              dayjs(selectedKey)
            )}
            onChange={(dates) => {
              if (dates === null) return;
              props.setSelectedKeys(
                dates.map((date: any) =>
                  dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
                )
              );
            }}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Son Arama Tarihi",
      dataIndex: "lastCallDate",
      key: "lastCallDate",
      sorter: true,
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
    },
    {
      title: "Tekrar Arama Tarihi",
      dataIndex: "againCallDate",
      key: "againCallDate",
      sorter: true,
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
    },
    {
      title: "Temsilci",
      dataIndex: "agent",
      key: "agent.id",
      render: (value: any) => value?.fullName ?? "-",
      hidden: !isAdmin,
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <AgentSearchSelect
            style={{ width: 200 }}
            optionValueKey="id"
            allowClear={false}
            value={props.selectedKeys[0]}
            onSelect={(value: any) => props.setSelectedKeys([value])}
          />
        </TableSearchFilter>
      ),
    },
  ];
};

const LeadCPComponent = (props: ILeadCPComponentProps) => {
  return (
    <>
      {props.isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      {props.isAdmin && (
        <>
          <Form.Item label="Durum" name="state" rules={[required]}>
            <StateTreeSelect />
          </Form.Item>
          <Form.Item
            label="Ad Soyad"
            name="fullName"
            rules={[required, min(4), max(60)]}
          >
            <Input placeholder="Ad soyad giriniz" />
          </Form.Item>
          <Form.Item label="E-Posta" name="email" rules={[email, required]}>
            <Input placeholder="E-Posta giriniz" />
          </Form.Item>
        </>
      )}
      <Form.Item label="Telefon" name="phone">
        <InputPhone />
      </Form.Item>
      <Form.Item label="İkincil Telefon" name="phone2">
        <InputPhone />
      </Form.Item>
      {props.isAdmin && (
        <>
          <Form.Item label="Kaynak" name="source">
            <SourceSearchSelect />
          </Form.Item>
          <Form.Item label="Müşteri Tipi" name="type" rules={[required]}>
            <Select
              placeholder="Müşteri tipi seçiniz"
              options={props.constants?.leadTypes}
            />
          </Form.Item>
          <Form.Item label="Temsilci" name="agent">
            <AgentSearchSelect />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default LeadCPComponent;
