import { useContext, useRef } from "react";
import { LeadCrudApi } from "../../client/EntityApi";
import CrudTable, {
  ICrudTableRefMethods,
} from "../../components/common/crud/CrudTable";
import { ConstantContext, UserContext } from "../../context";
import LeadCPComponent, {
  getLeadCPColumns,
} from "../../components/forms/crud/LeadCPComponent";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import {
  PhoneOutlined,
  EyeOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import CallAddModal, {
  ICallAddModalRefMethods,
} from "../../components/modals/CallAddModal";
import { useNavigate } from "react-router-dom";
import OrderAddModal, {
  IOrderAddModalRefMethods,
} from "../../components/modals/OrderAddModal";
import { getFormattedDate } from "../../helpers/UtilHelper";
import { Typography } from "antd";

const { Text } = Typography;

const LeadCrudPage = () => {
  const { user } = useContext(UserContext);
  const constants = useContext(ConstantContext);
  const navigate = useNavigate();
  const isAdmin = user?.roles.includes("ROLE_ADMIN");
  const crudTableRef = useRef<ICrudTableRefMethods>(null);
  const callAddModalRef = useRef<ICallAddModalRefMethods>(null);
  const orderAddModalRef = useRef<IOrderAddModalRefMethods>(null);

  return (
    <>
      <CrudTable
        ref={crudTableRef}
        entityLabel="Müşteri"
        api={LeadCrudApi}
        hideAddProcess={!isAdmin}
        hideDeleteProcess={!isAdmin}
        columns={getLeadCPColumns(constants, isAdmin)}
        expandable={{
          expandedRowRender: (row: any) => (
            <>
              <Text strong>Kaynak Geçmişi</Text>
              <ul>
                {row.sourceHistories.map((sourceHistory: any) => (
                  <li>
                    {sourceHistory.source.name +
                      " / " +
                      getFormattedDate(
                        sourceHistory.createdAt,
                        "DD.MM.YYYY HH:mm"
                      )}
                  </li>
                ))}
              </ul>
            </>
          ),
        }}
        addFormItems={
          <LeadCPComponent constants={constants} isAdmin={isAdmin} />
        }
        editFormItems={
          <LeadCPComponent
            constants={constants}
            isAdmin={isAdmin}
            isEdit={true}
          />
        }
        extraRowProcess={(row: any) => (
          <>
            <CrudTableProcessButton
              tooltipText="Detaya Git"
              icon={<EyeOutlined />}
              onClick={() => navigate("/musteri-detay/" + row.id)}
            />
            <CrudTableProcessButton
              tooltipText="Arama Kaydı Ekle"
              icon={<PhoneOutlined />}
              onClick={() => {
                callAddModalRef.current?.form.setFieldsValue({
                  leadData: row["@id"],
                  state: row.state?.["@id"],
                });
                callAddModalRef.current?.setOpen(true);
              }}
            />
            {isAdmin && (
              <CrudTableProcessButton
                tooltipText="Sipariş Kaydı Ekle"
                icon={<ShoppingCartOutlined />}
                onClick={() => {
                  orderAddModalRef.current?.form.setFieldsValue({
                    leadData: row["@id"],
                  });
                  orderAddModalRef.current?.setOpen(true);
                }}
              />
            )}
          </>
        )}
        setEditFields={(row) => {
          return {
            ...row,
            agent: row.agent
              ? { label: row.agent.fullName, value: row.agent["@id"] }
              : undefined,
            source: row.source
              ? { label: row.source.name, value: row.source["@id"] }
              : undefined,
            state: row.state?.["@id"],
          };
        }}
        beforeEditOperation={(values: any) => {
          let agent = null;
          if (values.agent) {
            agent =
              typeof values.agent === "object"
                ? values.agent.value
                : values.agent;
          }

          let source = null;
          if (values.source) {
            source =
              typeof values.source === "object"
                ? values.source.value
                : values.source;
          }

          return { ...values, agent: agent, source: source };
        }}
      />
      <CallAddModal
        ref={callAddModalRef}
        afterAction={() => crudTableRef.current?.refreshData()}
      />
      <OrderAddModal ref={orderAddModalRef} />
    </>
  );
};

export default LeadCrudPage;
