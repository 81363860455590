import { Form, Input, Select, Tag } from "antd";
import { max, min, required } from "../../../helpers/FormRuleHelper";
import { getConstantValue } from "../../../helpers/UtilHelper";
import YesNoSelect from "../../common/YesNoSelect";

interface IAgentCPComponentProps {
  isEdit?: boolean;
  constants?: any;
}

export const getAgentCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Ad", dataIndex: "firstname", key: "firstname" },
    { title: "Soyad", dataIndex: "lastname", key: "lastname" },
    { title: "Kullanıcı Adı", dataIndex: "username", key: "username" },
    {
      title: "Aktif mi?",
      dataIndex: "isActive",
      key: "isActive",
      filters: constants?.yesNo,
      filterMultiple: false,
      render: (value: boolean) => (
        <Tag color={value ? "green" : "red"}>{value ? "Evet" : "Hayır"}</Tag>
      ),
    },
    {
      title: "Rol",
      dataIndex: "roles",
      key: "roles",
      filters: constants?.roles,
      render: (value: any) =>
        value.map((role: any, index: number) => (
          <Tag key={index}>{getConstantValue(role, constants?.roles)}</Tag>
        )),
    },
  ];
};

const AgentCPComponent = (props: IAgentCPComponentProps) => {
  return (
    <>
      {props.isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="firstname"
        label="Ad"
        rules={[required, min(2), max(30)]}
      >
        <Input placeholder="Ad giriniz" />
      </Form.Item>
      <Form.Item
        name="lastname"
        label="Soyad"
        rules={[required, min(2), max(30)]}
      >
        <Input placeholder="Soyad giriniz" />
      </Form.Item>
      <Form.Item
        name="username"
        label="Kullanıcı Adı"
        rules={[required, min(4), max(30)]}
      >
        <Input placeholder="Kullanıcı adı giriniz" />
      </Form.Item>
      <Form.Item
        name="plainPassword"
        label="Şifre"
        rules={props.isEdit ? [min(4), max(255)] : [required, min(4), max(255)]}
      >
        <Input.Password
          placeholder={
            props.isEdit
              ? "Şifre değiştirmek istemiyorsanız boş bırakın"
              : "Şifre giriniz"
          }
        />
      </Form.Item>
      <YesNoSelect />
      <Form.Item
        name="roles"
        label="Rol"
        rules={[required]}
        initialValue="ROLE_USER"
      >
        <Select options={props.constants?.roles} />
      </Form.Item>
    </>
  );
};

export default AgentCPComponent;
