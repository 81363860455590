import { useParams } from "react-router-dom";
import CallCrudPage from "./crud/CallCrudPage";
import OrderCrudPage from "./crud/OrderCrudPage";

const LeadDetailPage = () => {
  const { id } = useParams();
  return (
    <>
      <CallCrudPage id={id} />
      <OrderCrudPage id={id} />
    </>
  );
};

export default LeadDetailPage;
