import { Form, FormInstance, Input, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { forwardRef, useImperativeHandle, useState } from "react";
import { max, min, required } from "../../helpers/FormRuleHelper";
import AuthApi from "../../client/AuthApi";

export interface IPasswordUpdateModalRefMethods {
  setOpen: (open: boolean) => void;
  form: FormInstance;
}

const PasswordUpdateModal = forwardRef((_, ref) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    setOpen: setOpen,
    form: form,
  }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Şifre Güncelle"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          if (values.newPassword !== values.newPasswordAgain) {
            message.warning("Şifreler uyuşmuyor.");
            return;
          }
          setLoading(true);
          AuthApi.renewPassword(values)
            .then((response) => {
              message.success(response.message);
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Güncelle"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="oldPassword"
          label="Eski Şifre"
          rules={[required, min(4), max(255)]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="Yeni Şifre"
          rules={[required, min(4), max(255)]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="newPasswordAgain"
          label="Yeni Şifre Tekrar"
          rules={[required, min(4), max(255)]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default PasswordUpdateModal;
