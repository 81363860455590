import { DatePicker as AntdDatePicker } from "antd";

const DatePicker = (props: any) => {
  return (
    <AntdDatePicker
      {...props}
      style={{ width: "100%" }}
      showTime={props.showTime}
      format={props.showTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY"}
    />
  );
};

export default DatePicker;
