import { Form, Select } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { Rule } from "antd/es/form";

interface IYesNoSelectProps {
  name?: string;
  label?: string;
  rules?: Rule[];
  initialValue?: boolean;
}

const YesNoSelect = (props: IYesNoSelectProps) => {
  return (
    <Form.Item
      name={props.name ?? "isActive"}
      label={props.label ?? "Aktif mi?"}
      rules={props.rules ?? [required]}
      initialValue={props.initialValue ?? true}
    >
      <Select
        options={[
          { label: "Evet", value: true },
          { label: "Hayır", value: false },
        ]}
      />
    </Form.Item>
  );
};

export default YesNoSelect;
