import { api } from "./Axios";

const AuthApi = {
  login: (values: any) => {
    return api.post("login", values).then((response: any) => response.data);
  },
  profileUpdate: (values: any) => {
    return api.put("profile", values).then((response: any) => response.data);
  },
  renewPassword: (values: any) => {
    return api
      .put("renew-password", values)
      .then((response: any) => response.data);
  },
  refreshToken: (refreshToken: string) => {
    return api
      .post("refresh-token", { refreshToken: refreshToken })
      .then((response: any) => response.data);
  },
  currentUser: () => {
    return api.get("current-user").then((response: any) => response.data);
  },
};

export default AuthApi;
