import { Button, Divider, Row } from "antd";
import { ReactNode } from "react";

interface ITableSearchFilterProps {
  filterDropdownProps: any;
  children: ReactNode;
}

const TableSearchFilter = (props: ITableSearchFilterProps) => {
  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      {props.children}
      <Divider style={{ margin: "8px 0px" }} />
      <Row justify="space-between">
        <Button
          type="link"
          size="small"
          onClick={() => props.filterDropdownProps.clearFilters?.()}
        >
          Sıfırla
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => props.filterDropdownProps.confirm()}
        >
          Tamam
        </Button>
      </Row>
    </div>
  );
};

export default TableSearchFilter;
