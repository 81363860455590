import { useContext } from "react";
import CrudTable from "../../components/common/crud/CrudTable";
import { ConstantContext, UserContext } from "../../context";
import CallCPComponent, {
  getCallCPColumns,
} from "../../components/forms/crud/CallCPComponent";
import { getCrudApi } from "../../client/CrudApi";
import dayjs from "dayjs";
import { Typography } from "antd";

const { Text } = Typography;

interface ICallCrudPageProps {
  id?: string;
}

const CallCrudPage = (props: ICallCrudPageProps) => {
  const { user } = useContext(UserContext);
  const constants = useContext(ConstantContext);
  const isAdmin = user?.roles.includes("ROLE_ADMIN");

  return (
    <CrudTable
      entityLabel="Arama Kaydı"
      api={getCrudApi("/leads/" + props.id + "/calls")}
      columns={getCallCPColumns(constants)}
      hideDefaultRowProceses={!isAdmin}
      addFormItems={<CallCPComponent />}
      editFormItems={<CallCPComponent isEdit={true} />}
      expandable={{
        rowExpandable: (row) => row.note,
        expandedRowRender: (row) => (
          <>
            <Text strong>Not: </Text> {row.note}
          </>
        ),
      }}
      setEditFields={(row) => {
        return {
          ...row,
          id: row["@id"],
          callDate: row.callDate ? dayjs(row.callDate) : null,
          againCallDate: row.againCallDate ? dayjs(row.againCallDate) : null,
          state: row.state["@id"],
        };
      }}
    />
  );
};

export default CallCrudPage;
