import { SubscriptionCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/common/crud/CrudTable";
import SubscriptionCPComponent, {
  getSubscriptionCPColumns,
} from "../../components/forms/crud/SubscriptionCPComponent";

const SubscriptionCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Abonelik"
      api={SubscriptionCrudApi}
      hideSearchBar
      columns={getSubscriptionCPColumns()}
      addFormItems={<SubscriptionCPComponent />}
      editFormItems={<SubscriptionCPComponent isEdit={true} />}
    />
  );
};

export default SubscriptionCrudPage;
