import { forwardRef, useImperativeHandle, useState } from "react";
import { Form, FormInstance, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import OrderAddForm from "../forms/OrderAddForm";
import { OrderCrudApi } from "../../client/EntityApi";

export interface IOrderAddModalRefMethods {
  setOpen: (open: boolean) => void;
  form: FormInstance;
}

const OrderAddModal = forwardRef((_, ref) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    setOpen: setOpen,
    form: form,
  }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Sipariş Kaydı Ekle"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          setLoading(true);
          OrderCrudApi.create(values)
            .then(() => {
              message.success("Sipariş kaydı eklendi.");
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Ekle"
    >
      <Form form={form} layout="vertical">
        <OrderAddForm />
      </Form>
    </Modal>
  );
});

export default OrderAddModal;
