import CrudTable from "../../components/common/crud/CrudTable";
import { getCrudApi } from "../../client/CrudApi";
import OrderCPComponent, {
  getOrderCPColumns,
} from "../../components/forms/crud/OrderCPComponent";
import dayjs from "dayjs";
import { useContext } from "react";
import { UserContext } from "../../context";

interface IOrderCrudPageProps {
  id?: string;
}

const OrderCrudPage = (props: IOrderCrudPageProps) => {
  const { user } = useContext(UserContext);
  const isAdmin = user?.roles.includes("ROLE_ADMIN");

  return (
    <CrudTable
      entityLabel="Sipariş Kaydı"
      api={getCrudApi("/leads/" + props.id + "/orders")}
      columns={getOrderCPColumns()}
      hideDefaultRowProceses={!isAdmin}
      addFormItems={<OrderCPComponent />}
      editFormItems={<OrderCPComponent isEdit={true} />}
      setEditFields={(row) => {
        return {
          ...row,
          id: row["@id"],
          startDate: row.startDate ? dayjs(row.startDate) : null,
          finishDate: row.finishDate ? dayjs(row.finishDate) : null,
        };
      }}
    />
  );
};

export default OrderCrudPage;
