import { forwardRef, useImperativeHandle, useState } from "react";
import { Form, FormInstance, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { CallCrudApi } from "../../client/EntityApi";
import CallAddForm from "../forms/CallAddForm";

export interface ICallAddModalRefMethods {
  setOpen: (open: boolean) => void;
  form: FormInstance;
}

interface ICallAddModalProps {
  afterAction?: () => void;
}

const CallAddModal = forwardRef((props: ICallAddModalProps, ref) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    setOpen: setOpen,
    form: form,
  }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Arama Kaydı Ekle"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          setLoading(true);
          CallCrudApi.create(values)
            .then(() => {
              props.afterAction && props.afterAction();
              message.success("Arama kaydı eklendi.");
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Ekle"
    >
      <Form form={form} layout="vertical">
        <CallAddForm />
      </Form>
    </Modal>
  );
});

export default CallAddModal;
