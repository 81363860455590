import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import { removeCredentials, saveCredentials } from "../helpers/AuthHelper";
import AuthApi from "./AuthApi";

const baseURL = process.env.REACT_APP_BASE_URL;

export const api = axios.create({ baseURL: baseURL });

api.interceptors.request.use(
  (config: any) => {
    let headers = { Authorization: "" };

    const accessToken = Cookies.get("accessToken");
    if (accessToken) headers.Authorization = `Bearer ${accessToken}`;

    config.headers = headers;

    return config;
  },
  (error: any) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: any) => {
    let errorMessage = "";
    if (error.response.data.errorCode === "AUTH01") {
      const refreshToken = Cookies.get("refreshToken");
      if (refreshToken) {
        errorMessage = error.response.data.message;
        await AuthApi.refreshToken(refreshToken).then((response) =>
          saveCredentials(response.accessToken, response.refreshToken)
        );
      } else {
        removeCredentials();
      }
    } else if (error.response.status === 422) {
      error.response.data.violations.forEach((violation: any) => {
        errorMessage += violation.message + "\n";
      });
    } else if (error.response.data["hydra:description"]) {
      errorMessage = error.response.data["hydra:description"];
    } else {
      errorMessage = error.response.data.message;
    }
    message.error(errorMessage);

    return Promise.reject(error);
  }
);
