import { Form, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import DatePicker from "../common/DatePicker";

interface IOrderAddFormProps {
  isSubresource?: boolean;
}

const OrderAddForm = (props: IOrderAddFormProps) => {
  return (
    <>
      {!props.isSubresource && (
        <Form.Item name="leadData" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        label="Paket"
        name="package"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Paket giriniz" />
      </Form.Item>
      <Form.Item
        label="Süre"
        name="period"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Süre giriniz" />
      </Form.Item>
      <Form.Item label="Başlangıç Tarihi" name="startDate" rules={[required]}>
        <DatePicker showTime />
      </Form.Item>
      <Form.Item label="Bitiş Tarihi" name="finishDate" rules={[required]}>
        <DatePicker showTime />
      </Form.Item>
    </>
  );
};

export default OrderAddForm;
