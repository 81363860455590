import { StateCrudApi } from "../../../client/EntityApi";
import { getOptionsFromEntities } from "../../../helpers/UtilHelper";
import SearchSelect from "../../common/SearchSelect";

const StateSearchSelect = (props: any) => {
  const { optionValueKey, ...rest } = props;

  return (
    <SearchSelect
      {...rest}
      placeholder="Durum aramak için yazın.."
      searchAction={(searchValue, setOptions, setLoading) => {
        return StateCrudApi.getAll(true, 1, 10, searchValue)
          .then((response) => {
            setOptions(
              getOptionsFromEntities(
                response["hydra:member"],
                "name",
                optionValueKey ?? "@id"
              )
            );
          })
          .finally(() => setLoading(false));
      }}
    />
  );
};

export default StateSearchSelect;
