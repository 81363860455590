import { Form, Input, Tag } from "antd";
import { required } from "../../../helpers/FormRuleHelper";
import { getFormattedDate } from "../../../helpers/UtilHelper";
import CallAddForm from "../CallAddForm";

interface ICallCPComponentProps {
  isEdit?: boolean;
  isSubresource?: boolean;
}

export const getCallCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Müşteri Adı / Telefonu",
      dataIndex: "leadData",
      key: "leadData",
      render: (value: any) => value.displayName,
    },
    {
      title: "Arayan Temsilci",
      dataIndex: "agent",
      key: "agent",
      render: (value: any) => value.fullName,
    },
    {
      title: "Arama Tarihi",
      dataIndex: "callDate",
      key: "callDate",
      sorter: true,
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
    },
    {
      title: "Durum/Alt Durum",
      dataIndex: "state",
      key: "state",
      render: (value: any) =>
        value ? (value.parent?.name ?? "-") + " / " + value?.name : "-",
    },
    {
      title: "Tekrar Aransın mı ?",
      dataIndex: "isAgainCall",
      key: "isAgainCall",
      filters: constants?.yesNo,
      filterMultiple: false,
      render: (value: boolean) => (
        <Tag color={value ? "green" : "red"}>{value ? "Evet" : "Hayır"}</Tag>
      ),
    },
    {
      title: "Tekrar Arama Tarihi",
      dataIndex: "againCallDate",
      key: "againCallDate",
      sorter: true,
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
    },
  ];
};

const CallCPComponent = (props: ICallCPComponentProps) => {
  return (
    <>
      {props.isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <CallAddForm isSubresource={true} />
    </>
  );
};

export default CallCPComponent;
