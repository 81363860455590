import { Form, Input } from "antd";
import { max, min, required } from "../../../helpers/FormRuleHelper";
import ParentStateSelect from "../formItems/ParentStateSelect";

interface IStateCPComponentProps {
  isEdit?: boolean;
}

export const getStateCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Üst Durum",
      dataIndex: "parent",
      key: "parent",
      render: (value: any) => value?.name ?? "-",
    },
    { title: "Durum Adı", dataIndex: "name", key: "name" },
  ];
};

const StateCPComponent = (props: IStateCPComponentProps) => {
  return (
    <>
      {props.isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item name="parent" label="Üst Durum">
        <ParentStateSelect />
      </Form.Item>
      <Form.Item
        name="name"
        label="Durum Adı"
        rules={[required, min(2), max(50)]}
      >
        <Input placeholder="Durum adı giriniz" />
      </Form.Item>
    </>
  );
};

export default StateCPComponent;
