import { Form, Input } from "antd";
import { required } from "../../../helpers/FormRuleHelper";
import OrderAddForm from "../OrderAddForm";
import { getFormattedDate } from "../../../helpers/UtilHelper";

interface IOrderCPComponentProps {
  isEdit?: boolean;
}

export const getOrderCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Paket", dataIndex: "package", key: "package" },
    { title: "Süre", dataIndex: "period", key: "period" },
    {
      title: "Başlangıç Tarihi",
      dataIndex: "startDate",
      key: "startDate",
      sorter: true,
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
    },
    {
      title: "Bitiş Tarihi",
      dataIndex: "finishDate",
      key: "finishDate",
      sorter: true,
      render: (value: any) =>
        value ? getFormattedDate(value, "DD.MM.YYYY HH:mm") : "-",
    },
  ];
};

const OrderCPComponent = (props: IOrderCPComponentProps) => {
  return (
    <>
      {props.isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <OrderAddForm isSubresource={true} />
    </>
  );
};

export default OrderCPComponent;
