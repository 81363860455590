import debounce from "lodash.debounce";
import { Select, Spin } from "antd";
import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

export interface ISearchSelectRefMethods {
  setOptions: (options: any[]) => void;
  setLoading: (loading: boolean) => void;
}

interface ISearchSelectProps {
  placeholder?: string;
  style?: any;
  searchAction?: (
    searchValue: string,
    setOptions: (options: any[]) => void,
    setLoading: (loading: boolean) => void
  ) => void;
  defaultOption?: any;
  onSelect?: (value: any, option: any) => void;
  onClear?: () => void;
  optionRender?: (option: any) => ReactNode;
  multiple?: boolean;
  labelInValue?: boolean;
}

const SearchSelect = forwardRef((props: ISearchSelectProps, ref) => {
  const { searchAction, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const debouncedCallback = debounce((searchValue) => {
    setOptions([]);
    setLoading(true);
    searchAction && searchAction(searchValue, setOptions, setLoading);
  }, 500);

  useEffect(() => {
    props.defaultOption && setOptions([props.defaultOption]);
  }, [props.defaultOption]);

  useImperativeHandle(ref, () => ({
    setOptions: setOptions,
    setLoading: setLoading,
  }));

  return (
    <Select
      allowClear
      {...rest}
      labelInValue={props.labelInValue ?? false}
      mode={props.multiple ? "multiple" : undefined}
      showSearch
      options={options}
      filterOption={false}
      onSearch={debouncedCallback}
      placeholder={props.placeholder}
      onSelect={props.onSelect}
      onClear={props.onClear}
      notFoundContent={loading ? <Spin size="small" /> : "Bulunamadı."}
      optionRender={props.optionRender}
    />
  );
});

export default SearchSelect;
