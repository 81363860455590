import { Form, Input } from "antd";
import { max, min, required } from "../../../helpers/FormRuleHelper";

interface ISourceCPComponentProps {
  isEdit?: boolean;
}

export const getSourceCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Kaynak Adı", dataIndex: "name", key: "name" },
  ];
};

const SourceCPComponent = (props: ISourceCPComponentProps) => {
  return (
    <>
      {props.isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Kaynak Adı"
        rules={[required, min(2), max(50)]}
      >
        <Input placeholder="Kaynak adı giriniz" />
      </Form.Item>
    </>
  );
};

export default SourceCPComponent;
