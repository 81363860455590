import { SourceCrudApi } from "../../client/EntityApi";
import CrudTable from "../../components/common/crud/CrudTable";
import SourceCPComponent, {
  getSourceCPColumns,
} from "../../components/forms/crud/SourceCPComponent";

const SourceCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Kaynak"
      api={SourceCrudApi}
      hideSearchBar
      columns={getSourceCPColumns()}
      addFormItems={<SourceCPComponent />}
      editFormItems={<SourceCPComponent isEdit={true} />}
    />
  );
};

export default SourceCrudPage;
