import { Form, Input, Select } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import dayjs from "dayjs";
import { useWatch } from "antd/es/form/Form";
import DatePicker from "../common/DatePicker";
import StateTreeSelect from "./formItems/StateTreeSelect";

const { TextArea } = Input;

interface ICallAddFormProps {
  isSubresource?: boolean;
}

const CallAddForm = (props: ICallAddFormProps) => {
  const isAgainCall = useWatch("isAgainCall");

  return (
    <>
      {!props.isSubresource && (
        <Form.Item name="leadData" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item label="Durum" name="state" rules={[required]}>
        <StateTreeSelect />
      </Form.Item>
      <Form.Item
        label="Arama Tarihi"
        name="callDate"
        rules={[required]}
        initialValue={dayjs()}
      >
        <DatePicker showTime />
      </Form.Item>
      <Form.Item
        label="Tekrar Aransın Mı ?"
        name="isAgainCall"
        rules={[required]}
        initialValue={false}
      >
        <Select
          options={[
            { label: "Evet", value: true },
            { label: "Hayır", value: false },
          ]}
        />
      </Form.Item>
      {isAgainCall && (
        <Form.Item label="Tekrar Arama Tarihi" name="againCallDate">
          <DatePicker showTime />
        </Form.Item>
      )}
      <Form.Item label="Not" name="note" rules={[min(10), max(2000)]}>
        <TextArea rows={2} placeholder="Not giriniz" />
      </Form.Item>
    </>
  );
};

export default CallAddForm;
