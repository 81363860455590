import { Button, Checkbox, Form, Row, Image, Divider } from "antd";
import LoginForm from "../../components/forms/LoginForm";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AuthApi from "../../client/AuthApi";
import Cookies from "js-cookie";
import { saveCredentials } from "../../helpers/AuthHelper";
import { useForm } from "antd/lib/form/Form";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loginForm] = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const username = Cookies.get("rememberMe");
    if (!username) return;
    loginForm.setFieldsValue({ username: username, rememberMe: true });
  }, [loginForm]);

  const onFinish = (values: any) => {
    setLoading(true);
    AuthApi.login(values)
      .then(({ accessToken, refreshToken }) => {
        if (values.rememberMe === true)
          Cookies.set("rememberMe", values.username);
        else Cookies.remove("rememberMe");

        saveCredentials(accessToken, refreshToken);
        navigate("/");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Row justify="center">
        <Image
          preview={false}
          style={{ borderRadius: 8 }}
          src="assets/logo.jpg"
          width={220}
          height={55}
        />
      </Row>
      <Divider />
      <Form form={loginForm} layout="vertical" onFinish={onFinish}>
        <LoginForm />
        <Form.Item name="rememberMe" valuePropName="checked">
          <Checkbox>Beni Hatırla</Checkbox>
        </Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Giriş Yap
        </Button>
      </Form>
    </>
  );
};

export default LoginPage;
