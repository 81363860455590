import { Avatar, Dropdown, MenuProps, Row } from "antd";
import {
  SettingOutlined,
  LockOutlined,
  LogoutOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { useContext, useRef } from "react";
import { UserContext } from "../../context";
import ProfileUpdateModal, {
  IProfileUpdateModalRefMethods,
} from "../modals/ProfileUpdateModal";
import PasswordUpdateModal, {
  IPasswordUpdateModalRefMethods,
} from "../modals/PasswordUpdateModal";
import { colors } from "../../theme";
import { removeCredentials } from "../../helpers/AuthHelper";

const ProfileMenu = () => {
  const { user } = useContext(UserContext);

  const profileUpdateModalRef = useRef<IProfileUpdateModalRefMethods>(null);
  const passwordUpdateModalRef = useRef<IPasswordUpdateModalRefMethods>(null);

  const items: MenuProps["items"] = [
    {
      key: "0",
      onClick: () => profileUpdateModalRef.current?.setOpen(true),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>{user ? user?.fullName : <LoadingOutlined />}</span>
          <SettingOutlined />
        </Row>
      ),
    },
    {
      key: "1",
      onClick: () => passwordUpdateModalRef.current?.setOpen(true),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>Şifremi Güncelle</span>
          <LockOutlined />
        </Row>
      ),
    },
    {
      key: "2",
      danger: true,
      onClick: () => removeCredentials(),
      label: (
        <Row style={{ width: 200, padding: 4 }} justify="space-between">
          <span>Çıkış Yap</span>
          <LogoutOutlined />
        </Row>
      ),
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Avatar
          size={40}
          style={{ backgroundColor: colors.secondary, cursor: "pointer" }}
        >
          {user ? user?.initialLettersOfName : <LoadingOutlined />}
        </Avatar>
      </Dropdown>
      <ProfileUpdateModal ref={profileUpdateModalRef} />
      <PasswordUpdateModal ref={passwordUpdateModalRef} />
    </>
  );
};

export default ProfileMenu;
